
import React, { useEffect, useState } from 'react';
import LoanHistoryDetails from '../components/loanhistorydetail';

function Loanhistory (){
return(
    <>
       <LoanHistoryDetails />
    </>
)

}

export default  Loanhistory;