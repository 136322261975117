import logo from './logo.svg';
import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {    Routes,  Route,  Link} from 'react-router-dom';
import { BrowserRouter } from "react-router-dom";
import Loanhistory from "./loanhistory/loanhistory";

function App() {
  return (
    <>
    <BrowserRouter>
    <Routes>  
        <Route exact path='/' element={< Loanhistory />}></Route>  
        
    </Routes>  
  </BrowserRouter>
  </>
  );
}

export default App;
