import React, { useState ,useRef ,useEffect  } from 'react';
import $ from 'jquery';
const LoanHistoryTable = ({ loanHistory, selectedMonth }) => {
     return (
        <div className="table-responsive" >
            <table id="loanHistoryTable" className="table table-bordered table-striped" >
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Account Type</th>
                        <th>Disbursement Date</th>
                        <th>Amount</th>
                        <th>Status</th>
                        <th>Paid But Not Close</th>
                        <th>Current Balance</th>
                        <th>Installment Amt:</th>
                        <th>Credit Limit:</th>
                        <th>Month</th>
                        <th>C0_0</th>
                        <th>C1_10</th>
                        <th>C11_30</th>
                        <th>C31_60</th>
                        <th>C61_90</th>
                        <th>C91_180</th>
                        <th>C180_P</th>
                    </tr>
                </thead>
                <tbody>
                    {loanHistory.map((item, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.accountType}</td>
                            <td>{item.disbursmentDate}</td>
                            <td>{item.amount}</td>
                            <td>{item.status}</td>
                            <td>{item.paidButNotClose}</td>
                            <td>{item.currentBalance}</td>
                            <td>{item.istallmentAmount}</td>
                            <td>{item.creditLimit}</td>
                            <td>{selectedMonth}</td>
                            {item.months
                                .filter((month) => month.month === selectedMonth)
                                .map((month, index) => (
                                    <React.Fragment key={index}>
                                        <td>{month.C0_0}</td>
                                        <td>{month.C1_10}</td>
                                        <td>{month.C11_30}</td>
                                        <td>{month.C31_60}</td>
                                        <td>{month.C61_90}</td>
                                        <td>{month.C91_180}</td>
                                        <td>{month.C180_P}</td>
                                    </React.Fragment>
                                ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

const Loan = () => {
    const [selectedMonth, setSelectedMonth] = useState('3');

    const handleMonthChange = (e) => {
        setSelectedMonth(e.target.value);
    };
    const tableRef = useRef(null);

    useEffect(() => {
      // Initialize DataTables
      $(tableRef.current).DataTable();
    }, []);


    const loanHistory  = [
        {
            accountType: 'Personal Loan',
            disbursmentDate: '05/12/2022',
            amount: '3,000',
            status: 'Active',
            paidButNotClose: 'false',
            currentBalance: '0',
            istallmentAmount: '',
            creditLimit: '',
            months: [
                {
                    month: "3",
                    C0_0: "5",
                    C1_10: "0",
                    C11_30: "0",
                    C31_60: "0",
                    C61_90: "0",
                    C91_180: "0",
                    C180_P: "0"

                },
                {
                    month: "6",
                    C0_0: "10",
                    C1_10: "0",
                    C11_30: "0",
                    C31_60: "0",
                    C61_90: "0",
                    C91_180: "0",
                    C180_P: "0"

                },
                {
                    month: "9",
                    C0_0: "5",
                    C1_10: "0",
                    C11_30: "0",
                    C31_60: "0",
                    C61_90: "0",
                    C91_180: "0",
                    C180_P: "0"

                },
                {
                    month: "12",
                    C0_0: "11",
                    C1_10: "0",
                    C11_30: "0",
                    C31_60: "0",
                    C61_90: "0",
                    C91_180: "0",
                    C180_P: "0"

                }

            ]

        },
        {
            accountType: 'Personal Loan 2',
            disbursmentDate: '05/10/2023',
            amount: '4,000',
            status: 'Inactive',
            paidButNotClose: 'True',
            currentBalance: '0',
            istallmentAmount: '',
            creditLimit: '',
            months: [
                {
                    month: "3",
                    C0_0: "5",
                    C1_10: "0",
                    C11_30: "0",
                    C31_60: "0",
                    C61_90: "0",
                    C91_180: "0",
                    C180_P: "0"

                },
                {
                    month: "6",
                    C0_0: "10",
                    C1_10: "0",
                    C11_30: "0",
                    C31_60: "0",
                    C61_90: "0",
                    C91_180: "0",
                    C180_P: "0"

                },
                {
                    month: "9",
                    C0_0: "5",
                    C1_10: "0",
                    C11_30: "0",
                    C31_60: "0",
                    C61_90: "0",
                    C91_180: "0",
                    C180_P: "0"

                },
                {
                    month: "12",
                    C0_0: "11",
                    C1_10: "0",
                    C11_30: "0",
                    C31_60: "0",
                    C61_90: "0",
                    C91_180: "0",
                    C180_P: "0"

                }

            ]

        },
        {
            accountType: 'Home Loan',
            disbursmentDate: '05/02/2021',
            amount: '5,000',
            status: 'Closed',
            paidButNotClose: 'False',
            currentBalance: '0',
            istallmentAmount: '',
            creditLimit: '',
            months: [
                {
                    month: "3",
                    C0_0: "5",
                    C1_10: "0",
                    C11_30: "0",
                    C31_60: "0",
                    C61_90: "0",
                    C91_180: "0",
                    C180_P: "0"

                },
                {
                    month: "6",
                    C0_0: "10",
                    C1_10: "0",
                    C11_30: "0",
                    C31_60: "0",
                    C61_90: "0",
                    C91_180: "0",
                    C180_P: "0"

                },
                {
                    month: "9",
                    C0_0: "5",
                    C1_10: "0",
                    C11_30: "0",
                    C31_60: "0",
                    C61_90: "0",
                    C91_180: "0",
                    C180_P: "0"

                },
                {
                    month: "12",
                    C0_0: "11",
                    C1_10: "0",
                    C11_30: "0",
                    C31_60: "0",
                    C61_90: "0",
                    C91_180: "0",
                    C180_P: "0"

                }

            ]

        }
    ];

    return (
        <>
          <h5 className='stripe'>Loan History</h5>
          <div className='filterBox'>
          <label style={{ minWidth: '150px' }}>
            <input type="radio" value="3" onChange={handleMonthChange} name="loanhistory2" checked={selectedMonth === '3'} />
             &nbsp;3 Months
          </label>
          <label style={{ minWidth: '150px' }}>
            <input type="radio" value="6" onChange={handleMonthChange} name="loanhistory2" checked={selectedMonth === '6'} />
            &nbsp;6 Months
          </label>
          <label style={{ minWidth: '150px' }}>
            <input type="radio" value="9" onChange={handleMonthChange} name="loanhistory2" checked={selectedMonth === '9'} />
            &nbsp;9 Months
          </label>
          <label style={{ minWidth: '150px' }}>
            <input type="radio" value="12" onChange={handleMonthChange} name="loanhistory2" checked={selectedMonth === '12'} />
            &nbsp;12 Months
          </label>
            </div>
          <LoanHistoryTable loanHistory={loanHistory} selectedMonth={selectedMonth}  ref={tableRef}/>
          </>
      );
    };
    
    export default Loan;